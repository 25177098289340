$('[data-toggle="tooltip"]').tooltip();

var copyToClipboard = function (text) {
  var $temp = $('<input />');

  $('body').append($temp);

  $temp.val(text).select();
  document.execCommand('copy');
  $temp.remove();
};

$('[data-copy-to-clipboard]').on('click', function () {
  copyToClipboard($(this).data('copy-to-clipboard'));
});

$(document).on('show.bs.tooltip', function (event) {
  var $delay = $(event.target);

  if ($delay && $delay.length && $delay.data('delay') && $delay.data('delay').hide) {
    $delay.addClass('bounce-in');

    setTimeout(function () {
      $delay.removeClass('bounce-in');
      $delay.tooltip('hide');
    }, $delay.data('delay').hide);
  }
});

$("body").on("click", "[data-show]", (event) => {
  var $element = $(event.currentTarget);

  $($element.attr("data-show")).removeClass("d-none");
});

$("body").on("click", "[data-hide]", (event) => {
  var $element = $(event.currentTarget);

  $($element.attr("data-hide")).addClass("d-none");
});

$("body").on("click", '[data-toggle="show"]', (event) => {
  var $element = $(event.currentTarget);

  $($element.attr("data-target")).removeClass("hide");
  $($element.attr("data-target")).addClass("show");
});

$("body").on("click", '[data-toggle="hide"]', (event) => {
  var $element = $(event.currentTarget);

  $($element.attr("data-target")).removeClass("show");
  $($element.attr("data-target")).addClass("hide");
});

$(document).ready(function () {
  $(
    'img[src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="]',
  ).lazyload();
});

$(".carousel").carousel();

window.draggable = function (obj) {
  var $draggable = obj.drag;
  var $dragArea = obj.area;

  var initialTop = parseInt($draggable.css('top'), 10) || 0;
  var initialLeft = parseInt($draggable.css('left'), 10) || 0;
  var initialRight = parseInt($draggable.css('right'), 10) || 0;
  var initialBottom = parseInt($draggable.css('bottom'), 10) || 0;

  var maxLeft = initialLeft || initialRight;
  var maxTop = initialTop || initialBottom;

  function adjustPosition() {
    var areaWidth = $dragArea.innerWidth();
    var areaHeight = $dragArea.innerHeight();
    var paddingLeft = parseInt($dragArea.css('padding-left'), 10) || 0;
    var paddingTop = parseInt($dragArea.css('padding-top'), 10) || 0;
    var paddingRight = parseInt($dragArea.css('padding-right'), 10) || 0;
    var paddingBottom = parseInt($dragArea.css('padding-bottom'), 10) || 0;

    var elementWidth = $draggable.outerWidth();
    var elementHeight = $draggable.outerHeight();

    var currentLeft = parseInt($draggable.css('left'), 10);
    var currentTop = parseInt($draggable.css('top'), 10);

    var adjustedLeft = Math.max(maxLeft + paddingLeft, Math.min(currentLeft, areaWidth - elementWidth - maxLeft - paddingRight));
    var adjustedTop = Math.max(maxTop + paddingTop, Math.min(currentTop, areaHeight - elementHeight - maxTop - paddingBottom));

    $draggable.css({ top: adjustedTop, left: adjustedLeft });
  }

  $draggable.on('mousedown', function(e) {
    var offsetX = e.clientX - parseInt($draggable.css('left'), 10);
    var offsetY = e.clientY - parseInt($draggable.css('top'), 10);

    $(document).on('mousemove.draggable', function(e) {
      var newLeft = e.clientX - offsetX;
      var newTop = e.clientY - offsetY;

      var areaWidth = $dragArea.innerWidth();
      var areaHeight = $dragArea.innerHeight();
      var paddingLeft = parseInt($dragArea.css('padding-left'), 10) || 0;
      var paddingTop = parseInt($dragArea.css('padding-top'), 10) || 0;
      var paddingRight = parseInt($dragArea.css('padding-right'), 10) || 0;
      var paddingBottom = parseInt($dragArea.css('padding-bottom'), 10) || 0;
      var elementWidth = $draggable.outerWidth();
      var elementHeight = $draggable.outerHeight();

      newLeft = Math.max(maxLeft + paddingLeft, Math.min(newLeft, areaWidth - elementWidth - maxLeft - paddingRight));
      newTop = Math.max(maxTop + paddingTop, Math.min(newTop, areaHeight - elementHeight - maxTop - paddingBottom));

      $draggable.css({ top: newTop, left: newLeft });
    });

    $(document).on('mouseup.draggable', function() {
      $(document).off('mousemove.draggable mouseup.draggable');
    });

    e.preventDefault();
  });

  $(window).on('resize', adjustPosition);

  adjustPosition();
};
