$("#rejectedModal").on("hidden.bs.modal", function () {
  $("#rejectedModal textarea").val("");
  $("#rejectedModal .btn").removeClass("disabled").removeAttr("disabled");
});

$("#callModal").on("hidden.bs.modal", function () {
  $("#callModal .reason-message").html("");
  $("#callModal .btn-cancel").removeClass("d-none").addClass("d-block");
  $("#callModal .btn-close").removeClass("d-block").addClass("d-none");
});

$("#securityCodeModal").on("hidden.bs.modal", function () {
  $("#securityCodeModal .btn").removeClass("disabled").removeAttr("disabled");
  $("#securityCodeModal #securityCode").removeAttr("disabled").val("");
});

$(".modal").on("hidden.bs.modal", function () {
  $(this).find("form").trigger("reset");
});
