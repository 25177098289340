import consumer from "./consumer"

consumer.subscriptions.create("ChatChannel", {
  connected() {
    console.log("Chat channel connected")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.action == "send_message" && data.user_type == "from") {
      playAudio("#audio-notification");

      if (!$(`#chat_popup_${data.user_to.slug}`).length) {
        window.openChatPopup(data.user_to.slug, data.user_to.name);
      } else {
        window.createChatPopup(data.user_to.slug);

        var message = $(".message_tpl").clone();
        message.toggleClass("message_tpl message");

        message.find('.message_type').addClass('in');

        message.find('.avatarTpl').attr('src', data.user_from.avatar || '/assets/profile.png');

        message.find(".content").append(window.urlify(data.content));
        message.find(".author").text(data.user_from.name);
        message.find(".datetime").text(data.user_from.created_at);

        message.removeClass('d-none');

        $(`#chat_popup_${data.user_to.slug} .messages`).append(message);

        $(`#chat_popup_${data.user_to.slug} .card-body`).scrollTop(
          $(`#chat_popup_${data.user_to.slug} .messages`).height()
        );
      }
    }

    if (data.action == "send_message" && data.user_type == "to") {
      playAudio("#audio-notification");

      if (!$(`#chat_popup_${data.user_from.slug}`).length) {
        window.openChatPopup(data.user_from.slug, data.user_from.name);
      } else {
        window.createChatPopup(data.user_from.slug);

        var message = $(".message_tpl").clone();

        message.toggleClass("message_tpl message");

        message.find('.message_type').addClass('out');

        message.find('.avatarTpl').attr('src', data.user_from.avatar || '/assets/profile.png');

        message.find(".content").append(window.urlify(data.content));
        message.find(".author").text(data.user_from.name);
        message.find(".datetime").text(data.user_from.created_at);

        message.removeClass('d-none');

        $(`#chat_popup_${data.user_from.slug}`).find(".messages").append(message);

        $(`#chat_popup_${data.user_from.slug} .card-body`).scrollTop(
          $(`#chat_popup_${data.user_from.slug} .messages`).height()
        );
      }
    }
  }
});
