export default class ConferenceModule {
  constructor(args) {
    this.events = {};
    this.iceServers = args.iceServers;

    this.$shareStream = args.shareStreamElement;
    this.$remoteStream = args.remoteStreamElement;
    this.$localStream = args.localStreamElement;

    this._initializePeerConnection();
    this._initializeMediaAccess();
  }

  // PUBLIC METHODS

  setLocalStreamStatus(args) {
    if (this.localStream) {
      const kind = Object.keys(args)[0];
      const enabled = Object.values(args)[0];

      this.localStream.getTracks().forEach((track) => {
        if (track && track.kind === kind) {
          track.enabled = enabled;
        }
      });
    }

    if (this.pc && this.pc.connectionState === "connected") {
      this.sendToServer(args);
    }
  }

  async handleVideoOfferMsg(offer) {
    this._initializePeerConnection();

    const desc = new RTCSessionDescription(offer);

    await this.pc.setRemoteDescription(desc);

    this.addTracks();

    const answer = await this.pc.createAnswer();

    await this.pc.setLocalDescription(answer);

    this.sendToServer(this.pc.localDescription);
  }

  async handleVideoAnswerMsg(answer) {
    console.log("handle video answer message");

    const desc = new RTCSessionDescription(answer);

    await this.pc.setRemoteDescription(desc);
  }

  handleNewICECandidateMsg(args) {
    console.log("handle new ice candidate message");

    const candidate = new RTCIceCandidate(args);

    this.pc.addIceCandidate(candidate);
  }

  async startShareScreen() {
    try {
      if (this.$shareStream && this.$shareStream.srcObject) {
        // this.$shareStream.srcObject.getTracks().forEach((track) => {

        //   track.stop();

        //   const sender = this.pc.getSenders().find(s => s.track === track);

        //   if (sender) {
        //     this.pc.removeTrack(sender);
        //   }
        // });

        this.sendToServer({ type: 'screen-sharing-stopped' });
      }

      this.screenShareStream = await navigator.mediaDevices.getDisplayMedia({
        video: { MediaSource: "screen" }
      });

      this.$shareStream.srcObject = this.screenShareStream;

      this.addTracks(this.screenShareStream);
      this.addTracks();

      this.sendToServer({ type: 'screen-sharing-started' });

      this.screenShareStream.getVideoTracks()[0].addEventListener('ended', () => {
        // console.log('The user has ended sharing the screen');

        this.screenShareStream.getTracks().forEach((track) => {
          track.stop();
        });

        this.pc.getSenders().forEach((sender) => {
          if (this.screenShareStream.getTracks().includes(sender.track)) {
            this.pc.removeTrack(sender);
          }
        });

        this.sendToServer({ type: 'screen-sharing-stopped' });
      });
    } catch (error) {
      console.error(`getDisplayMedia error: ${error.name}`, error);
    }
  }

  addTracks(_stream) {
    const stream = _stream || this.localStream;

    if (stream) {
      stream.getTracks().forEach((track) => {
        this.sendToServer({ [track.kind]: track.enabled });

        this.pc.addTrack(track, stream);
      });
    }
  }

  sendToServer(message) {
    this.emit('send', message);
  }

  on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(callback);
  }

  emit(event, ...args) {
    if (this.events[event]) {
      this.events[event].forEach((callback) => callback(...args));
    }
  }

  // PRIVATE METHODS

  _handleRemoveTrackEvent () {}

  _handleICEConnectionStateChangeEvent () {}

  _handleICEGatheringStateChangeEvent () {}

  _handleSignalingStateChangeEvent () {}

  _handleConnectionStateChangeEvent () {
    switch (this.pc.connectionState) {
      case "connected":
        this.emit("connected");

        break;
      case "disconnected":
        this.emit("disconnected");

        break;
      case "failed":
        this.emit("failed");

        break;
      case "closed":
        this.emit("closed");

        break;
    }
  }

  async _handleNegotiationNeededEvent () {
    console.log("on negotiation needed");

    await this.pc
      .createOffer()
      .then((offer) => this.pc.setLocalDescription(offer))
      .then(() => {
        this.sendToServer(this.pc.localDescription);
      })
  }

  _initializePeerConnection() {
    this.pc = new RTCPeerConnection({
      iceServers: this.iceServers
    });

    this.pc.onicecandidate = (event) => this._handleICECandidateEvent(event);
    this.pc.ontrack = (event) => this._handleTrackEvent(event);
    this.pc.onnegotiationneeded = () => this._handleNegotiationNeededEvent();
    this.pc.onremovetrack = () => this._handleRemoveTrackEvent();
    this.pc.onicegatheringstatechange = () => this._handleICEGatheringStateChangeEvent();
    this.pc.onsignalingstatechange = () => this._handleSignalingStateChangeEvent();
    this.pc.onconnectionstatechange = () => this._handleConnectionStateChangeEvent();
  }

  _handleICECandidateEvent(event) {
    console.log("on ice candidate event");

    if (event.candidate) {
      this.sendToServer(event.candidate);
    }
  }

  _handleTrackEvent(event) {
    console.log("on track event");

    if (event.streams && event.streams[0]) {
      this.$remoteStream.srcObject = event.streams[0];
    } else {
      console.log('No stream found in the received track event.');
    }
  }

  async _initializeMediaAccess() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoAllowed = await this._isPermissionGranted(devices, 'camera', "videoinput");
      const audioAllowed = await this._isPermissionGranted(devices, 'microphone', "audioinput");

      if (!videoAllowed) {
        this.emit('disabledVideo');
      }

      if (!audioAllowed) {
        this.emit('disabledAudio');
      }

      if (audioAllowed) {
        await this._getUserMedia(videoAllowed, audioAllowed);
      } else {
        this.emit('audioDeviceError');
      }
    } catch (error) {
      console.error("Error enumerating media devices:", error);
    }
  }

  async _isPermissionGranted(devices, permissionName, deviceKind) {
    const deviceExists = devices.filter(device => device.kind === deviceKind).length > 0;

    try {
      const permission = await navigator.permissions.query({ name: permissionName });

      return deviceExists && permission.state !== "denied";
    } catch (error) {
      console.log(`Error checking permissions for ${permissionName}:`, error);

      return deviceExists;
    }
  }

  async _getUserMedia(videoAllowed, audioAllowed) {
    try {
      this.localStream = await navigator.mediaDevices.getUserMedia({ video: videoAllowed, audio: audioAllowed });

      this.emit('mediaStream', this.localStream);
    } catch (error) {
      console.error("Error obtaining local stream:", error);

      this.emit('mediaStreamError');
    }
  }
}
