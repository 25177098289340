$("#occupation_id").change(function () {
  var id = this.value;
  var $select = $("#specialties[multiple]");
  var $needRegistration = $("#registration_number");
  var $needRegistrationGroup = $needRegistration.parents(".form-group");

  $select.find("option").remove();
  $needRegistration.val("");
  $needRegistration.attr("disabled", true);

  if ($(this).find("option").eq(id).data("need-registration")) {
    $needRegistration.attr("disabled", false);
    $needRegistrationGroup.removeClass("d-none").addClass("d-block");
  } else {
    $needRegistration.attr("disabled", true);
    $needRegistrationGroup.removeClass("d-block").addClass("d-none");
  }

  $.ajax({
    type: "GET",
    url: "/occupations/" + id + "/specialties",
    success: function (data) {
      console.log("Submission was successful.");
      console.log(data);

      data.forEach(function (item) {
        $select.append(
          '<option value="' + item.id + '">' + item.name + "</option>",
        );
      });
    },
    error: function (data) {
      console.log("An error occurred.");
      console.log(data);
    },
  });
});

var lastValidSelection = null;

$("#specialties[multiple]").change(function () {
  var $select = $(event.target);

  if ($select.find(":selected").length > 2) {
    alert("Você só pode escolher 2!");
    $(this).val(lastValidSelection);
  } else {
    lastValidSelection = $(this).val();
  }
});
