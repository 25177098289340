var authenticityToken = $("meta[name='csrf-token']").attr("content");

$("#btn-call").click(function () {
  var professionalUserSlug = $(this).attr("data-user-slug");

  $("body").attr("data-calling", true);

  $.ajax({
    type: "post",
    url: "/calls",
    data: {
      professional_user_slug: professionalUserSlug,
      authenticity_token: authenticityToken,
    },
    success: function (data, textStatus, xhr) {
      console.log(xhr);
      console.log(data);
    },
    error: function (data, textStatus, xhr) {
      console.log(xhr);
      console.log(data);

      if (data.responseJSON.error === "call_in_progress") {
        $("#callModal").modal("show");
        $("#callModal .reason-message").html("Você já está em outra chamada.");
        $("#callModal .btn-cancel").removeClass("d-block").addClass("d-none");
        $("#callModal .btn-close").removeClass("d-none").addClass("d-block");
      }
    },
  });
});

$(".btn-call-accept").click(function () {
  $("#incomingCall").addClass("call-accepted");
  $("body").attr("data-calling", true);

  var userSlug = $(this).data("user-slug");

  $.ajax({
    type: "post",
    url: "/calls/accepts",
    data: {
      user_slug: userSlug,
      authenticity_token: authenticityToken,
    },
    success: function (data, textStatus, xhr) {
      console.log(xhr);
      console.log(data);
    },
    error: function (data, textStatus, xhr) {
      console.log(xhr);
      console.log(data);
    },
  });
});

$(".btn-security-code").on("click", async function (event) {
  var $form = $(event.target).parents("form");
  var isValid = $form[0].checkValidity();

  if (isValid) {
    event.preventDefault();
    event.stopPropagation();

    $("body").attr("data-calling", true);

    $("#securityCodeModal .btn").addClass("disabled");

    $("#callModal").modal("show");
    $("#securityCodeModal").modal("hide");

    playAudio("#audio-calling");

    $.ajax({
      type: $form.attr("method"),
      url: $form.attr("action"),
      data: $form.serialize(),
      success: function (data) {
        console.log("Submission was successful.");
        console.log(data);
      },
      error: function (data) {
        console.log("An error occurred.");
        console.log(data);
      },
    });
  }
});
