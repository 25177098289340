var addMessages = function (data) {
  var messages = "";

  if (typeof data === "object") {
    data.forEach(function (item) {
      var userFromName = item.user_from_name;
      var avatar = item.avatar;
      var userFromSlug = item.user_from_slug;
      var createdAt = item.created_at;
      var content = item.content;
      var userAvatar = avatar || "/assets/profile.png";

      messages +=
        "<li>" +
          '<button type="button" class="open-chat-popup btn btn-link text-left font-weight-normal" data-dismiss="modal" data-slug="' + userFromSlug + '" data-name="' + userFromName + '">' +
            '<div class="w-100 p-2 clearfix">' +
              '<img src="' + userAvatar + '" class="rounded-circle text-center open-chat-popup-image" width="24" height="24" />' +

              '<div class="open-chat-popup-message">' +
                '<div class="w-100 clearfix">' +
                  '<h5 class="m-0">' + userFromName + "</h5>" +
                  '<small class="d-block mb-2">' + createdAt + "</small>" +
                "</div>" +

                '<div class="w-100 clearfix text-overflow">' + content + "</div>" +
              "</div>" +
            "</div>" +
          "</button>" +
          "<hr />" +
        "</li>";
    });
  }

  return messages;
};

$("[data-load-messages]").on("click", function () {
  var $boxAllMessages = $("[data-box-all-messages]");

  $.ajax({
    type: "GET",
    url: "/messages/notifications",
    success: function (data) {
      if (typeof data === "object" && data.length) {
        $boxAllMessages.html(
          '<ul class="list-unstyled m-0">' + addMessages(data) + "</ul>",
        );
      } else {
        $boxAllMessages.html("Você não tem nenhuma mensagem no momento.");
      }
    },
    error: function (data) {
      console.log("An error occurred.");
      console.log(data);
    },
  });
});

$("body").on("keydown", 'textarea[name="content"]', (event) => {
  var $element = $(event.currentTarget);
  var $chat = $element.parents(".chat");

  if (
    (event.metaKey || event.ctrlKey || event.altKey || event.commandKey) &&
    event.keyCode === 13
  ) {
    $chat.find(".chat-btn-send").trigger("click");
  }
});

$("body").on("click", ".chat-btn-send", function (e) {
  var $element = $(e.currentTarget);

  $element.attr("disabled", true);

  e.preventDefault();

  var receiverSlug = $(e.currentTarget).parents(".chat").data("receiver-slug");
  var messageField = $(e.currentTarget)
    .parents(".chat")
    .find("textarea[name='content']");
  var content = messageField.val();
  var data = { content: content };

  if (content) {
    $.post("/messages/" + receiverSlug, data, function (response) {
      messageField.val("");
      $element.removeAttr("disabled");
      console.log("mensagem enviada");
    });
  }
});

$("body").on("click", ".open-chat-popup", function (e) {
  e.preventDefault();

  var receiverSlug = $(e.currentTarget).data("slug");
  var receiverName = $(e.currentTarget).data("name");
  var text = $(e.currentTarget).data("text") || "";

  if (!$("#chat_popup_" + receiverSlug).length) {
    window.openChatPopup(receiverSlug, receiverName);
  } else {
    window.createChatPopup(receiverSlug);
  }

  $("#chat_popup_" + receiverSlug)
    .find("textarea")
    .val(text);
});

window.openChatPopup = function (receiverSlug, receiverName) {
  window.createChatPopup(receiverSlug);

  $("#chat_popup_" + receiverSlug)
    .find(".card-footer")
    .css("visibility", "hidden");

  $("#chat_popup_" + receiverSlug)
    .find(".messages li")
    .remove();
  $("#chat_popup_" + receiverSlug)
    .find(".message-user-name-tpl")
    .html(receiverName);

  $.get("/messages/open/" + receiverSlug, function (data) {
    $("#chat_popup_" + receiverSlug)
      .find(".spinner-message")
      .remove();

    var userSlug = $("body").data("user-slug");

    $("#chat_popup_" + receiverSlug)
      .find(".messages li")
      .remove();
    $("#chat_popup_" + receiverSlug)
      .find(".card-footer")
      .css("visibility", "visible");

    if (typeof data === "object") {
      data.forEach(function (element) {
        var message = $(".message_tpl").clone(true);

        message.removeClass("message_tpl");

        if (userSlug === element.user_from_slug) {
          message.find(".message_type").addClass("in");
        } else {
          message.find(".message_type").addClass("out");
        }

        message
          .find(".avatarTpl")
          .attr("src", element.avatar || "/assets/profile.png");
        message.find(".content").append(window.urlify(element.content));

        if (
          element.profile && element.profile != window.location.href.split('#')[0] &&
          !$("#chat_popup_" + receiverSlug).find(".go-to-profile").length
        ) {
          $("#chat_popup_" + receiverSlug)
            .find(".card-footer")
            .append(
              '<a href="' +
                element.profile +
                '" class="go-to-profile float-left btn btn-outline-primary">Ir para o perfil</a>',
            );
        }

        message.find(".author").text(element.user_from_name);

        message.find(".datetime").text(element.created_at);

        message.removeClass("d-none");

        $("#chat_popup_" + receiverSlug)
          .find(".messages")
          .append(message.find("li"));
        $("#chat_popup_" + receiverSlug)
          .find(".card-body")
          .scrollTop(
            $("#chat_popup_" + receiverSlug)
              .find(".messages")
              .height(),
          );
      });
    }
  });
};

window.createChatPopup = function (receiverSlug) {
  if ($("#chat_popup_" + receiverSlug).length) {
    if ($("#chat_popup_" + receiverSlug + ".d-none").length) {
      $("#chat_popup_" + receiverSlug)
        .find(".collapse")
        .collapse("hide");
      $("#chat_popup_" + receiverSlug).removeClass("d-none");
    }

    return;
  }

  var chatTpl = $(".chatTpl").clone();

  chatTpl.find(".chat").attr("data-receiver-slug", receiverSlug);

  var chatHtml = chatTpl.html();

  chatHtml = chatHtml.replace(/chatOne1/g, "chat_messages_" + receiverSlug);
  chatHtml = chatHtml.replace(/chatTplId/g, "chat_popup_" + receiverSlug);

  var chatPopup = $(chatHtml);

  $(".chatWrapper").append(chatPopup);

  chatPopup.removeClass("d-none");

  chatPopup.find(".collapse").collapse("show");

  return chatPopup;
};
