import consumer from "./consumer"

consumer.subscriptions.create("CallChannel", {
  connected() {
    console.log('Call channel connected');
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log('Call channel disconnected');
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    // Professional
    if (data.action == 'calling') {
      var caller = data.content.caller;

      playAudio("#audio-calling");

      $('#incomingCall .call-from').html('Chamada de ' + caller.name);

      if (caller.avatar) {
        $('#incomingCall, #rejectedModal').find('img').attr({ 'src': caller.avatar, 'data-original': caller.avatar });
      }

      $('#incomingCall .btn-call-accept').data('user-slug', caller.slug);
      $('#rejectedModal #user_slug').val(caller.slug);

      if (!$('#rejectedModal:visible').length) {
        $('#incomingCall:not(.call-accepted)').modal('show');
      }
    }

    // User and Professional
    if (data.action == 'missed') {
      stopAudio("#audio-calling");

      $('#incomingCall').modal('hide');
      $('#callModal .reason-message').html('Profissional indisponível');
      $('#callModal .btn-cancel').removeClass('d-block').addClass('d-none');
      $('#callModal .btn-close').removeClass('d-none').addClass('d-block');
    }

    // User and Professional
    if (data.action == 'call_canceled') {
      stopAudio("#audio-calling");
      $('#incomingCall, #callModal').modal('hide');
    }

    // User and Professional
    if (data.action == 'call_accepted') {
      var conference_uuid = data.content.conference_uuid;

      stopAudio("#audio-calling");
      $('#incomingCall, #callModal').modal('hide');

      if ($('body').attr('data-calling')) {
        $('body').removeAttr('data-calling');
        window.location.href = '/conferences/' + conference_uuid;
      }
    }

    // User
    if (data.action == 'call_rejected') {
      var reason = '';

      if (data && data.content && data.content.reason) {
        reason = data.content.reason;
      }

      $('#rejectedModal').modal('hide');

      if ($('#callModal:visible').length) {
        $('#callModal .reason-message').html('Profissional indisponível<br />' + reason);
        $('#callModal .btn-cancel').removeClass('d-block').addClass('d-none');
        $('#callModal .btn-close').removeClass('d-none').addClass('d-block');
      } else {
        var professional_user_slug = data.content.professional_user_slug;
        var professional_user_name = data.content.professional_user_name;

        if (!$('#chat_popup_' + professional_user_slug).length) {
          window.openChatPopup(professional_user_slug, professional_user_name);
        } else {
          window.createChatPopup(professional_user_slug);
        }
      }
    }

    // User and Professional
    if (data.action == 'call_user_payment_unauthorized' || data.action == 'call_professional_payment_unauthorized') {
      $('#incomingCall, #callModal').modal('hide');
      $('body').removeAttr('data-calling');
    }

    // User
    if (data.action == 'call_user_payment_unauthorized') {
      $('#userPaymentUnauthorized').modal('show');
    }

    // Professional
    if (data.action == 'call_professional_payment_unauthorized') {
      $('#professionalPaymentUnauthorized').modal('show');
    }

    if (data.action == 'finish_user' && window.location.href.includes('/conferences/' + data.room_id)) {
      window.location.href = '/conferences/' + data.room_id + '/ratings';
      console.log('finish_user');
    }

    if (data.action == 'finish_professional' && window.location.href.includes('/conferences/' + data.room_id)) {
      console.log('finish_professional');
      window.location.href = `/profiles/${data.slug}#user-conference`;
    }
  }
});
