import consumer from "./consumer"

consumer.subscriptions.create("HomeChannel", {
  connected() {
    console.log('Home channel connected');
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log('Home channel disconnected');
  },

  received(data) {
    if (data.action == 'status_online') {
      $(`#${data.user_id} span.professional-online`)
        .removeClass('badge-danger')
        .addClass('badge-success')
        .attr('data-original-title', 'Disponível')
    }

    if (data.action == 'status_offline') {
      $(`#${data.user_id} span.professional-online`)
        .removeClass('badge-success')
        .addClass('badge-danger')
        .attr('data-original-title', 'Indisponível')
    }
  }
});
