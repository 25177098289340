import "inputmask";

import cep from "cep-promise";

import { isValid as isValidCpf } from "@fnando/cpf";
import { isValid as isValidCnpj } from "@fnando/cnpj";

$("body .reset-input-on-checked").on(
  "click",
  "input:checkbox",
  function (event) {
    var $checkbox = $(event.target);
    var $input = $checkbox
      .parents(".reset-input-on-checked")
      .find(':input:not([type="checkbox"])');

    if ($checkbox.is(":checked")) {
      $input.attr("disabled", true).val("");
    } else {
      $input.attr("disabled", false);
    }
  },
);

$("body").on("click", '[type="reset"]', (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $submit = $form.find('[type="submit"]');

  $submit.removeAttr("disabled");
});

$("body").on("click", "label[for]", (event) => {
  var $element = $(event.currentTarget);

  $("body")
    .find(`:input[id="${$element.attr("for")}"]:visible:not([readonly])`)
    .focus();
});

$("body").on("focus", ":input[readonly]", (event) => {
  var $element = $(event.currentTarget);

  $element.blur();
});

$("body").on("click", "[data-type-cpf]", (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $cpf = $form.find('[data-type="cpf"] :input');
  var $cnpj = $form.find('[data-type="cnpj"] :input');
  var $submit = $form.find('[type="submit"]');

  var cpfValue = $cpf.val();

  $cnpj.attr({ disabled: true, readonly: true });
  $cpf.removeAttr("disabled").removeAttr("readonly");

  if (isValidCpf(cpfValue)) {
    $submit.removeAttr("disabled");
  } else {
    $submit.attr("disabled", true);
  }

  if (cpfValue.length === 11) {
    if (isValidCpf(cpfValue)) {
      $submit.removeAttr("disabled");
    } else {
      setTimeout(() => {
        alert("CPF não encontrado.");
      });

      $submit.attr("disabled", true);
    }
  }
});

$("body").on("click", "[data-type-cnpj]", (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $cpf = $form.find('[data-type="cpf"] :input');
  var $cnpj = $form.find('[data-type="cnpj"] :input');
  var $submit = $form.find('[type="submit"]');

  var cnpjValue = $cnpj.val();

  $cpf.attr({ disabled: true, readonly: true });
  $cnpj.removeAttr("disabled").removeAttr("readonly");

  if (isValidCnpj(cnpjValue)) {
    $submit.removeAttr("disabled");
  } else {
    $submit.attr("disabled", true);
  }

  if (cnpjValue.length === 14) {
    if (isValidCnpj(cnpjValue)) {
      $submit.removeAttr("disabled");
    } else {
      setTimeout(() => {
        alert("CNPJ não encontrado.");
      });

      $submit.attr("disabled", true);
    }
  }
});

$("[data-type-cpf]:checked").click();
$("[data-type-cnpj]:checked").click();

$("body").on("input", "[data-cpf]", (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $submit = $form.find('[type="submit"]');
  var cpfValue = $element.val();

  if (cpfValue.length === 11) {
    if (isValidCpf(cpfValue)) {
      $submit.removeAttr("disabled");
    } else {
      setTimeout(() => {
        alert("CPF não encontrado.");
      });

      $submit.attr("disabled", true);
    }
  }
});

$("body").on("input", "[data-cnpj]", (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $submit = $form.find('[type="submit"]');
  var cnpjValue = $element.val();

  if (cnpjValue.length === 14) {
    if (isValidCnpj(cnpjValue)) {
      $submit.removeAttr("disabled");
    } else {
      setTimeout(() => {
        alert("CNPJ não encontrado.");
      });

      $submit.attr("disabled", true);
    }
  }
});

$("body").on("input", "[data-cep]", (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $submit = $form.find('[type="submit"]');
  var cepValue = $element.val();

  function fiels(v) {
    $form.find("#address_city").val((v && v.city) || "");
    $form.find("#address_state").val((v && v.state) || "");
    $form.find("#address_neighborhood").val((v && v.neighborhood) || "");
    $form.find("#address_street").val((v && v.street) || "");
  }

  fiels();

  if (cepValue.length === 8) {
    cep(cepValue)
      .then((v) => {
        fiels(v);
        $submit.removeAttr("disabled");
      })
      .catch((e) => {
        fiels();

        alert("CEP não encontrado.");

        $submit.attr("disabled", true);
      });
  }
});

$.each($("form"), function () {
  var $form = $(this);

  $.each($form.find("[data-mask]"), function () {
    var $input = $(this);

    Inputmask({
      mask: $input.data("mask"),
      autoUnmask: true,
      removeMaskOnSubmit: true,
      clearIncomplete: true,
    }).mask($input);
  });

  $.each($form.find("[data-date]"), function () {
    var $input = $(this);

    Inputmask({
      mask: "99/99/9999",
      autoUnmask: true,
      clearIncomplete: true,
      placeholder: "DD/MM/YYYY",
    }).mask($input);
  });

  $.each($form.find("[data-money]"), function () {
    var $input = $(this);

    Inputmask("currency", {
      autoUnmask: true,
      radixPoint: ",",
      groupSeparator: ".",
      allowMinus: false,
      prefix: "R$ ",
      suffix: " min",
      digits: 2,
      digitsOptional: false,
      rightAlign: false,
      unmaskAsNumber: true,
      removeMaskOnSubmit: true,
    }).mask($input);
  });
});
