// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";

import * as ActiveStorage from "@rails/activestorage";
import "@fortawesome/fontawesome-free/css/all.css";

import "lazyload";
import "bootstrap";
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js";

import "./ui";
import "./tabs";
import "./modal";
import "./forms";
import "./messages";
import "./flash_message";
import "./specialties";

import "channels";

import "./calls";
import "./conferences";

Rails.start();
ActiveStorage.start();

window.urlify = function (text) {
  if (typeof text !== 'string') {
    return text || '';
  }

  var urlRegex = /((https?:\/\/)|(www\.))[^\s]+[^\s.,]/g;

  return text.replace(urlRegex, function (url) {
    var newTab = "";

    try {
      var formattedUrl = url.startsWith("www.") ? "http://" + url : url;

      if (new URL(formattedUrl).hostname !== window.location.hostname) {
        newTab = ' target="_blank"';
      }

      return '<a href="' + formattedUrl + '"' + newTab + ">" + url + "</a>";
    } catch (e) {
      return url;
    }
  });
};

// SCHEDULE
var scheduleDateInterval;
var today = new Date();
var tomorrow = new Date();

tomorrow.setDate(today.getDate() + 1);

var day = String(tomorrow.getDate()).padStart(2, '0');
var month = String(tomorrow.getMonth() + 1).padStart(2, '0');
var year = tomorrow.getFullYear();
var formattedDate = day + '/' + month + '/' + year;

$("#schedule-date").datepicker({
  format: "dd/mm/yyyy",
  startDate: "+0d",
  language: "pt-BR",
}).datepicker('setDate', formattedDate);

$("#scheduleModal").on("shown.bs.modal", function () {
  scheduleDateInterval = setInterval(() => {
    var scheduleDate = $("#schedule-date").datepicker("getDate");
    var scheduleDateWithHour = new Date(scheduleDate);
    var scheduleDateStartTime = $("#scheduleModal")
      .find(":input[name='start_time']")
      .val();
    var scheduleDateStartTimeHour = 0;
    var scheduleDateStartTimeMinutes = 0;

    if (scheduleDateStartTime && scheduleDateStartTime !== "") {
      scheduleDateStartTimeHour = parseInt(scheduleDateStartTime.split(":")[0]);
      scheduleDateStartTimeMinutes = parseInt(
        scheduleDateStartTime.split(":")[1],
      );
    }

    scheduleDateWithHour.setHours(scheduleDateStartTimeHour);
    scheduleDateWithHour.setMinutes(scheduleDateStartTimeMinutes);

    $("#scheduleModal")
      .find(":input[name='start_at']")
      .val(scheduleDateWithHour);
  }, 300);
});

$("#scheduleModal").on("hidden.bs.modal", function () {
  clearInterval(scheduleDateInterval);
  $("#schedule-date").datepicker("setDate", formattedDate);
});

window.playAudio = function ($element) {
  var audio = $($element)[0];

  audio
    .play()
    .then(() => {
      console.log("Áudio reproduzido com sucesso!");
    })
    .catch((error) => {
      console.error("Erro ao reproduzir áudio:", error);
    });
};

window.stopAudio = function ($element) {
  var audio = $($element)[0];

  audio.currentTime = 0;

  audio.pause();
};

window.addToast = function (message, icon) {
  var iconHTML = "";

  if ($("#liveToast").length) {
    $("#liveToast").remove();
  }

  if (icon) {
    iconHTML = `<i class="fas ${icon} mr-2 text-white"></i>`;
  }

  $("body").append(`
    <div id="liveToast" class="position-fixed p-3" style="z-index: 99999999; left: 0; bottom: 0;">
      <div class="toast bg-dark hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
        <div class="toast-body text-white">
          ${iconHTML}
          ${message}
        </div>
      </div>
    </div>
  `);

  $("#liveToast .toast").toast("show");
};

$(".modal").on("shown.bs.modal", function () {
  $(
    'img[src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="]',
  ).lazyload();
});

// share social_buttons

$(".social-button a").on("click", function (e) {
  e.preventDefault();
  window.open(
    $(this).attr("href"),
    "sharer",
    "toolbar=0,status=0,width=548,height=325",
  );
});

/* Javascript to show and hide cookie banner using localstorage */
/* Shows the Cookie banner */
var showCookieBanner = function () {
  $('#helpcam-cookie-banner').fadeIn(function () {
    $('#helpcam-cookie-banner .alert').slideDown();
  });
};

/* Hides the Cookie banner and saves the value to localstorage */
var hideCookieBanner = function () {
  localStorage.setItem('cookieAccepted', 'yes');

  $('#helpcam-cookie-banner .alert').slideUp(function () {
    $('#helpcam-cookie-banner').fadeOut();
  });
};

/* Checks the localstorage and shows Cookie banner based on it. */
var initializeCookieBanner = function () {
  var isCookieAccepted = localStorage.getItem('cookieAccepted');

  if (isCookieAccepted === null) {
    localStorage.setItem('cookieAccepted', 'no');
    showCookieBanner();
  }

  if (isCookieAccepted === 'no') {
    showCookieBanner();
  }

  $('#helpcam-cookie-banner .btn-primary').on('click', function (event) {
    var $element = $(event.target);

    event.stopPropagation();

    hideCookieBanner();
  });
};

// COOKIE BANNER
initializeCookieBanner();

// FIX ANCHOR
$(document).on('click', 'a[href*="#"]', function (event) {
  event.preventDefault();
  event.stopPropagation();

  var href = $(this).attr('href');
  var currentUrl = window.location.href.split('#')[0];
  var targetUrl = href.split('#')[0];

  if (currentUrl !== targetUrl) {
    window.location.href = href;
  } else {
    var anchor = href.split('#')[1];

    if (anchor) {
      var targetElement = $('#' + anchor);

      if (targetElement.length) {
        $('html, body').animate({
          scrollTop: targetElement.offset().top
        }, 500);
      }
    }
  }

  return false;
});

function setupAutocomplete(inputSelector, listSelector) {
  // Adjust the width of the autocomplete list to match the parent element's width
  const adjustWidth = () => {
    const inputWidth = $(inputSelector).outerWidth() - 26;  // Get width of the input element including padding and border

    $(listSelector).width(inputWidth);  // Set the width of the autocomplete list
  };

  // Filter and display list items based on the input
  const filterAndShowItems = (inputVal) => {
    let hasVisibleItems = false;

    $(`${listSelector} .list-group-item`).each(function() {
      const text = $(this).text().toLowerCase();

      if (text.startsWith(inputVal)) {
        $(this).show();
        hasVisibleItems = true;
      } else {
        $(this).hide();
      }
    });

    // Show or hide the list based on the visibility of items
    $(listSelector).toggle(hasVisibleItems);
  };

  // Handle input event
  $(inputSelector).on('input', function() {
    const inputVal = $(this).val().toLowerCase();

    filterAndShowItems(inputVal);
  });

  // Handle focus event to adjust width and show all items if the input is empty
  $(inputSelector).on('focus', () => {
    adjustWidth();

    if (!$(inputSelector).val().trim()) {
      $(`${listSelector} .list-group-item`).show();
      $(listSelector).show();
    } else {
      filterAndShowItems($(inputSelector).val().toLowerCase());
    }
  });

  // Handle blur event to hide the list with a delay
  $(inputSelector).on('blur', () => {
    setTimeout(() => {
      $(listSelector).hide();
    }, 200);
  });

  // Handle click event on list items to select the value
  $(document).on('click', `${listSelector} .list-group-item`, function() {
    $(inputSelector).val($(this).text()).blur();
  });

  // Adjust the list width when the window is resized
  $(window).resize(adjustWidth);
}

// Usage of the function
$(function() {
  setupAutocomplete('#autocomplete-input', '#autocomplete-list');
});

$(document).on('click', "a[data-toggle='tooltip'], button[data-toggle='tooltip']", function() {
  var currentTitle = $(this).attr('data-original-title');
  var alternateTitle = $(this).data('alt-title');

  if (alternateTitle) {
    if (currentTitle !== alternateTitle) {
      $(this).attr('data-original-title', alternateTitle);
      $(this).data('alt-title', currentTitle);

      $(this).tooltip('hide').tooltip('dispose');
      $(this).tooltip();

      $(this).tooltip('show');
    } else {
      $(this).tooltip('hide');
    }
  } else {
    $(this).tooltip('hide');
  }
});

$('html').fadeIn();
