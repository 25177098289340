var $flashMessage = $(".alert-notice");

if ($flashMessage.length) {
  $flashMessage.slideDown();

  $flashMessage.on("close.bs.alert", function (event) {
    $(event.target).slideUp();

    event.preventDefault();
    event.stopPropagation();
  });
}
